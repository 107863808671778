/** @jsx jsx */
import { jsx } from 'theme-ui'

import { SEO } from "../components/seo"

import { Heading, Container, Box, Flex, Input, Button, Text } from '@theme-ui/components'
import { List } from "../components/molecules/list"
import { ImageCard } from "../components/molecules/imageCard"

import { useFirebase } from 'gatsby-plugin-firebase'
import { useStaticQuery, graphql, Node } from "gatsby"

import ocean from '../wallpapers/reddit/11.png'
import second from '../wallpapers/reddit/22.jpg'
import third from '../wallpapers/reddit/23.png';
import { Card, CardImage, CardTitle } from '../components/organisms/card'
import { ImageGrid } from '../containers/imageGrid'
import { WallpaperOfTheDay } from '../containers/wallpaperOfTheDay'
import { Modal } from '../components/organisms/modal'
import { useContext, useState, Fragment } from 'react'
import { app } from 'firebase'
import { Subscribe } from '../containers/subscribe'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "wallpapers"}, relativeDirectory: {eq: "gal-shir"}}) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              # Specify a fixed image and fragment.
              # The default width is 400 pixels
              fixed(width: 200, height: 360, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      reddit: allFile(filter: {sourceInstanceName: {eq: "wallpapers"}, relativeDirectory: {eq: "reddit"}}) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              # Specify a fixed image and fragment.
              # The default width is 400 pixels
              fixed(width: 200, height: 360, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    } 
  `)

  useFirebase((firebase: app.App) => {
    firebase.analytics().logEvent("page_view", { page_path: '/' })
  }, [])

  const images = data.allFile.edges
  const reddit = data.reddit.edges
  return (
    <Fragment>
      <SEO title="Home" />
      <Box sx={{ overflowX: 'hidden' }}>
        <Box sx={{ backgroundColor: 'primary', py: 4, marginBottom: 200, }}>
          <Container>
            <Flex sx={{ alignItems: "center" }}>
              <Heading as="h2" sx={{ flex: '1' }} variant="jumbo">Browse and discover beautiful mobile wallpapers from around the web</Heading>
              <Flex style={{ flex: '1', justifyContent: 'center', position: 'relative', minHeight: '350px' }}>
                <img width='236px' height='420px' sx={{ minWidth: '236px', height: '420px', position: 'absolute', left: '50px', top: '45px', boxShadow: '3px 3px 40px 3px rgba(0,0,0,0.4)' }} src={third as string} />
                <img width='241px' height='500px' sx={{ zIndex: 100, minWidth: '241px', height: '500px', position: 'absolute', left: '135px', boxShadow: '3px 3px 25px -2px rgba(0,0,0,0.7)' }} src={second as string} />
                <img width='200px' height='420px' sx={{ minWidth: '200px', height: '420px', position: 'absolute', left: '250px', top: '45px', boxShadow: '3px 3px 40px 3px rgba(0,0,0,0.4)' }} src={ocean as string} />
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Box>

      <Box mt={64} mb={[4, 128]} sx={{ backgroundColor: '#30cbff' }}>
        <Container>
          <WallpaperOfTheDay />
        </Container>
      </Box>

      <Box py={[2, 32]} >
        <Container>
          <Heading sx={{ fontWeight: 300 }} pb={4}>Latest Wallpapers</Heading>
          <ImageGrid />
        </Container>
      </Box>
    </Fragment>
  )
}



export default IndexPage
