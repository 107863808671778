/** @jsx jsx */
import { jsx } from 'theme-ui'

import { Heading, Link, Box, Flex, Grid, Button, Text } from '@theme-ui/components'
import { useState } from 'react';
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { app } from 'firebase';
import { QueryDocumentSnapshot } from '@google-cloud/firestore';
import { Card, CardTitle, CardActions, CardIntro } from '../components/organisms/card';
import { Link as RouteLink } from 'gatsby'

import like from '../images/like-match.svg';
import chats from '../images/chats.svg';
import star from '../images/star.svg'
import circ from '../images/cd.svg';

export const WallpaperOfTheDay = () => {
  const [image, setImage] = useState<any>(undefined)

  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .collection("wallpaperOfTheDay")
      .doc("20191220")
      .get()
      .then((data) => {
        return data.data()!.wallpaper.get()
      })
      .then((wall) => {
        setImage(wall)
      })
  }, []);

  console.log(image)

  if (!image) {
    return <Box sx={{}}>
      <Flex sx={{ flexDirection: ['column', 'row'], height: ['auto', '320px'], justifyContent: 'center', alignItems: 'center' }}>
        <Heading>Loading&hellip;</Heading>
      </Flex>
    </Box>
  }

  const {
    category,
    name,
    description,
    images,
  } = image.data()

  return (
    <Box>
      <Flex sx={{ flexDirection: ['column', 'row'], height: ['auto', '320px'] }}>
        <Box sx={{ flex: ['auto', 1] }}>
          <Link as={RouteLink} to={`/wallpapers/${image.id}`} sx={{ display: 'flex', height: ['auto', '440px'], my: [0, '-48px'], mt: ['-48px', '-48px'], mb: [0, '-48px'], maxWidth: '300px', mx: 'auto', boxShadow: '0 0 40px 2px rgba(0,0,0,0.4)', pb: 0 }}>
            <img src={images.thumb.url} sx={{ objectFit: 'cover', width: '100%', height: '440px' }} />
          </Link>
        </Box>
        <Flex sx={{ flex: ['auto', 1], alignItems: 'center', px: [0, 4, 0], pt: [4, 0, 0], pb: [4, 0, 0] }}>
          <Box>
            <Heading sx={{ fontSize: 18, fontWeight: 300 }} >Wallpaper of the day</Heading>
            <Heading sx={{ fontSize: 36, marginTop: 3 }}>{name}</Heading>
            <Text sx={{ marginBottom: 3 }}>{description}</Text>
            <Flex as={RouteLink} to={`/categories/${category}`} sx={{ alignItems: 'center' }}>
              <img width="24" height="24" sx={{ mr: 2 }} src={circ} />
              {category}
            </Flex>

            <Box mt={3}>
              <Link as={RouteLink} to={`/wallpapers/${image.id}`}>
                <img width="24" height="24" src={like} sx={{ marginRight: 2 }} />
              </Link>
              <Link as={RouteLink} to={`/wallpapers/${image.id}`}>
                <img width="24" height="24" src={star} sx={{ marginRight: 2 }} />
              </Link>
              <Link as={RouteLink} to={`/wallpapers/${image.id}`}>
                <img width="24" height="24" src={chats} sx={{ marginRight: 2 }} />
              </Link>
            </Box>
          </Box>

        </Flex>
      </Flex>
    </Box >
  )
}