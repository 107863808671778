/** @jsx jsx */
import { jsx } from 'theme-ui'

import { Heading, Link, Box, Flex, Grid, Button } from '@theme-ui/components'
import { useState } from 'react';
import { Link as RouteLink } from 'gatsby'
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { app } from 'firebase';
import { QueryDocumentSnapshot } from '@google-cloud/firestore';
import { Card, CardTitle, CardActions, CardIntro, CardSubtitle } from '../components/organisms/card';

import like from '../images/like-match.svg';
import chats from '../images/chats.svg';
import star from '../images/star.svg'
import circ from '../images/cd.svg';

export const ImageGrid = () => {
  const [images, setImages] = useState<any[]>([])

  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .collection("wallpapers")
      .orderBy("createdAt", "desc")
      .limit(9)
      .get()
      .then((data) => {
        setImages(data.docs)
      })
  }, []);

  if (images.length === 0) {
    return (
      <Box>
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', height: 320 }}>
          <Heading>Loading&hellip;</Heading>
        </Flex>
      </Box>
    )
  }

  return (
    <Box>
      <Grid columns={[1, 2, 3]} mb={5}>
        {images.map((f: QueryDocumentSnapshot) => {
          const {
            category,
            name,
            description,
            images
          } = f.data()
          return (
            <Box key={f.id}>
              <Card>
                <CardIntro>
                  <Flex as={RouteLink} to={`/categories/${category}`} sx={{ alignItems: 'center' }}>
                    <img width="24" height="24" sx={{ mr: 2 }} src={circ} />
                    {category}
                  </Flex>
                </CardIntro>
                <div>
                  <Link as={RouteLink} to={`/wallpapers/${f.id}`}>
                    <img src={images?.thumb?.url} height="500px" style={{ objectFit: 'cover', width: '100%' }} />
                  </Link>
                </div>
                <CardTitle>
                  {name}
                </CardTitle>
                <CardSubtitle>
                  {description}
                </CardSubtitle>
                <CardActions>
                  <Link as={RouteLink} to={`/wallpapers/${f.id}`}>
                    <img width="24" height="24" src={like} sx={{ marginRight: 2 }} />
                  </Link>
                  <Link as={RouteLink} to={`/wallpapers/${f.id}`}>
                    <img width="24" height="24" src={star} sx={{ marginRight: 2 }} />
                  </Link>
                  <Link as={RouteLink} to={`/wallpapers/${f.id}`} >
                    <img width="24" height="24" src={chats} sx={{ marginRight: 2 }} />
                  </Link>
                </CardActions>
              </Card>
            </Box>
          )
        })}
      </Grid>
      {/* <Flex pt={4}>
        <Button sx={{ flex: 1 }}>View more</Button>
      </Flex> */}

    </Box>
  )
}